.navbar {
    position: sticky;
    top: 0px;
    width: 100%;
  }

  .navBarContainer {  
    z-index: 999;
    height:37px;
    position: sticky; 
    top: 0px;
    background-color: whitesmoke;
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.2fr;
    grid-template-areas:
      "left center right";
  }
  
  .navBarLeft { grid-area: left; margin: auto; margin-left: 15px; display: flex; align-items: center; }
  .navBarCenter { grid-area: center; margin: auto; }
  .navBarRight { grid-area: right; margin: auto; font-size: smaller; display: flex; align-items: center; margin-right: 25px; }

  img.emojione {
    /* Override any img styles to ensure Emojis are displayed inline */
    margin: 0px !important;
    display: inline !important;
    height: auto;
    width: 50px;
  }