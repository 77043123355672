.tipsLeftContainer {
    display:flex;
    flex-wrap:wrap;
    font-family:'Comic Sans', cursive;
    margin-left:1vmin;
    margin-top:20px;
    /* forcer le height, sinon sticky disparaît :hover */
    height: 200px;
  }
  
  .tipsLeftBreak {
    flex-basis: 100%;
    height: 0;
  }

  .tipsLeftTitle {
    position: relative;
    display: block;
    font-family:'Comic Sans', cursive;
    margin-left: 7px;
  }

  .tipsLeftContent {
    position: relative;
    display: inline-block;
    margin-top: 0px;
  }

  .tipsLeftImage {    
    border:solid 2px #000;
    box-shadow:0 6px 6px -6px #000;
    display:inline-block;
    flex:1 1;    
    overflow:hidden;
    position:relative;
    margin:5px;
    transition: transform 0.3s;
    /* filter: opacity(0.85); */
  }

  .tipsLeftImage:hover {
    transform: scale(1.1);
    /* filter: opacity(1); */
  }


  /* test superposition image et overlay  */
  .infoSerie {
    position: relative;
    top: 0;
    left: 0;
    margin-left:2vmin;
  }

  .infoSerie-couv {
    position: relative;
    top: 0;
    left: 0;
  }
  .infoSerie-overlay {
    position: absolute;
    top: 0;
    left: 0;
  }


  .tipsLeftImageBg {
    background-color:#fff;
    border:solid 2px #000;
    margin:0;
    padding:3px 10px;
  }
  
  .tipsLeftImageTL {
    left:-6px;
    position:absolute;
    top:-2px;
    transform:skew(-15deg);
  }

  .tipsLeftImageTR {
    right:-6px;
    position:absolute;
    top:-2px;
    transform:skew(-15deg);
  }
  
  .tipsLeftImageBL {
    left:-6px;
    position:absolute;
    bottom:-2px;
    transform:skew(-15deg);
  }

  .tipsLeftImageBR {
    bottom:-2px;
    position:absolute;
    right:-6px;
    transform:skew(-15deg);
  }
  
  