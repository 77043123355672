/* Styles pour les écrans d'ordinateur */
@media only screen and (min-width: 768px) {
  .serie-comic {
    display:flex;
    flex-wrap:wrap;
    font-family:'Comic Sans', cursive;
  }

  .resume-text {
    margin-top: 2.5em;
    margin-left: .5em;
    margin-right: .5em;
    margin-bottom: .5em;
    font-size: max(1.1vmax, 12px);
  }

  .checkbox-album label{
    font-weight:600;
    font-family:'Comic Sans', cursive;
    cursor: pointer;
  }
  
  .auteurs { font-size: max(1.6vmin, 12px); margin-top: 4vmin; margin-bottom: 4vmin;}

  .serie-text {
    background-color:#fff;
    border:solid 2px #000;
    margin:0;
    padding:3px 10px;
    font-size: max(1.7vmin, 12px);
  }  
}

/* Styles pour les écrans mobiles */
@media only screen and (max-width: 767px) {
  .serie-comic {
    display:flex;
    flex-wrap:wrap;
  }
  
  .resume-text {
    margin-top: 2.5em;
    margin-left: .5em;
    margin-right: .5em;
    margin-bottom: .5em;
    font-size: 10px;
  }

  .checkbox-album label{
    font-weight:600;
    cursor: pointer;
  }

  .auteurs { font-size: 10px; margin-top: 30px; margin-bottom: 20px;}

  .serie-text {
    background-color:#fff;
    border:solid 2px #000;
    margin:0;
    padding:3px 10px;
    font-size: 12px;
  }  
}

.admin-menu {
  margin:5px;
  padding:5px;
}  
.button-admin {
  margin-left: 0px;
}
.button-admin-delete {
  margin-left: 20px;
}

.spaceContainerMobile {  
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "space";
}

.spaceContainer {  
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-template-areas:
    "left space right";
}

.space { grid-area: space; margin: auto; margin-top: 0px;}
.left { grid-area: left; margin: auto; margin-top: 0px; padding:0.5vmin; margin-left: 10px; }
.right { grid-area: right; margin: auto; margin-top: 0px; padding:0.5vmin; margin-left: 10px; }

.albumContainer {  
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "image detail";
}

.image { grid-area: image; }
.detail { grid-area: detail; }




.serie-panel {
  z-index: 0;
  background-color:#fff;
  border:solid 2px #000;
  box-shadow:0 6px 6px -6px #000;
  display:inline-block;
  flex:1 1;
  margin:1vmin;
  overflow:hidden;
  position:relative;
  border-radius: 2%;
  height:100%;
  width:100%;
}

.serie-speech, .serie-speech-arrow-none {
  background-color:#fff;
  border:solid 2px #000;
  border-radius:12px;
  display:inline-block;
  margin:.5em;
  padding:.5em 1em;
  position:relative;
}

.serie-speech:before {
  border:solid 12px transparent;
  border-left:solid 12px #000;
  border-top:solid 12px #000;
  bottom:-24px;
  content:"";
  height:0;
  left:24px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}

.serie-speech:after {
  border:solid 10px transparent;
  border-left:solid 10px #fff;
  border-top:solid 10px #fff;
  bottom:-19px;
  content:"";
  height:0;
  left:27px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}



.serie-top-left {
  left:-6px;
  position:absolute;
  top:-2px;
  transform:skew(-15deg);
}

.serie-top-right {
  right:-6px;
  position:absolute;
  top:-2px;
  transform:skew(-15deg);    
}

.serie-bottom-right {
  bottom:-2px;
  position:absolute;
  right:-6px;
  transform:skew(-15deg);
  transition: transform 0.3s;
}

.serie-bottom-left {
  bottom:-2px;
  position:absolute;
  left:-6px;
  transform:skew(-15deg);
}

.serie-panel img{    
  z-index: -1;
  margin-bottom: -7px;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
}

.serie-panel img:hover{
  /* transform: scale(1.1); */
  /* filter: blur(1px); */
}

.serie-panel a{
  z-index: 1;
  width: 40%;
}
.serie-panel:nth-child(n) {
  flex-basis: 0;
}

.serie-bg-blue {
  background-image:radial-gradient(circle, lightblue, deepskyblue);
}

.serie-bg-orange {
  background-image:radial-gradient(circle, yellow, orange);
}

.serie-bg-green {
  background-image:radial-gradient(circle, palegreen, yellowgreen);
}

.serie-bg-red {
  background-image:radial-gradient(circle, lightcoral, tomato);
}

.checkbox-album {
  margin: auto;
  display:inline-block;
  padding: 10px;
}

.checkbox-album-mobile {
  transform: scale(0.6);
}

.dedicace:hover{
  cursor: pointer;
}

.checkbox-wrapper input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;  
  margin-left: 15px;
  border-radius: 0.15em;
  width: 1.4vmin;
  height: 1.4vmin;
  outline: none;
  cursor: pointer;
}

.mobile input[type="checkbox"]{
  padding: 15px;
  width: 0.5em;
  height: 0.5em;
}
.checkbox-wrapper span {
  font-size: max(1.4vmin, 12px);
  cursor: pointer;
}
