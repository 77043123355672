/* Styles pour les écrans d'ordinateur */
@media only screen and (min-width: 768px) {
  .comic {
    display:flex;
    flex-wrap:wrap;
    font-family:'Comic Sans', cursive;
    padding:1vmin;
    margin: auto;
  }

  .panelFaro {
    z-index: 0;
    border:solid 2px #000;
    box-shadow:0 6px 6px -6px #000;
    display:inline-block;
    flex:1 1;
    height: 21vmin;
    width: 25vmin;
    margin:1vmin;
    overflow:hidden;
    position:relative;
  }

  .panelFaro img{
    z-index: -1;
    width: 100%;
    height: 200%;
    transition: transform 0.3s;
  }

}

/* Styles pour les écrans mobiles */
@media only screen and (max-width: 767px) {
  .comic {
    display:flex;
    flex-wrap:wrap;
    margin: auto;
    justify-content: center;  /* Centre horizontalement les éléments */
    align-items: center;      /* Centre verticalement les éléments */
  }

  .panelFaro {
    z-index: 0;
    border:solid 2px #000;
    box-shadow:0 6px 6px -6px #000;
    display:inline-block;
    flex:1 1;
    height: 28vmin;
    width: 29vmin;
    margin:1vmin;
    overflow:hidden;
    position:relative;
  }
  
  .panelFaro img{
    z-index: -1;
    width: 100%;
    height: 150%;
    transition: transform 0.3s;
  }

  .top-left {
    font-size: 1.3em;
  }

  .bottom-right {    
    display: none;
  }
}



.text {
  background-color:#fff;
  border:solid 2px #000;
  margin:0;
  padding:3px 10px;
}

.top-left {
  left:-6px;
  position:absolute;
  top:-2px;
  transform:skew(-15deg);
}

.bottom-right {
  bottom:-2px;
  position:absolute;
  right:-6px;
  transform:skew(-15deg);
}

.panelFaro img:hover{
  transform: scale(1.1);
  /* filter: blur(1px); */
}

.panelFaro a{
  z-index: 1;
  width: 40%;
}
.panelFaro:nth-child(n) {
  flex-basis: 0;
}
  
  /* Inside this text some words <mark>are highlighted</mark> and some aren’t. */
mark {
  margin: 0 -0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;  
  background-image: linear-gradient(
    to right,    
    rgba(255, 225, 0, 0.1),
    rgba(255, 225, 0, 0.7) 4%,
    rgba(255, 225, 0, 0.3)
  );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

/* centrage bouton filtre bedetheque */
.filtreContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  justify-content: stretch;
  align-content: stretch;
  align-items: start;
  grid-template-areas:
    ". filtre .";
}

.filtre { grid-area: filtre; margin: auto; margin-top: 15px;}