.tipsRightContainer {
    display:flex;
    flex-wrap:wrap;
    font-family:'Comic Sans', cursive;
    padding:2vmin;
  }
  
  .tipsRightTitle {
    position: relative;
    display: block;
    font-family:'Comic Sans', cursive;
    margin-left: 1vmax;
  }

  .tipsRightContent {    
    margin-top: 0px;
  }

  .tipsRightImage {
    z-index: 0;
    border:solid 2px #000;
    box-shadow:0 6px 6px -6px #000;
    display:inline-block;
    flex:1 1;
    overflow:hidden;
    position:relative;    
    margin: 5px;
    transition: transform 0.3s;
    filter: opacity(0.85);
  }

  .tipsRightImage:hover {
    transform: scale(1.1);
    filter: opacity(1);
    cursor: pointer;
  }

  .tipsRightImageBg {
    background-color:#fff;
    border:solid 2px #000;
    margin:0;
    padding:3px 10px;
  }
  
  .tipsRightImageTL {
    left:-6px;
    position:absolute;
    top:-2px;
    transform:skew(-15deg);
  }

  .tipsRightImageTR {
    right:-6px;
    position:absolute;
    top:-2px;
    transform:skew(-15deg);
  }

  .tipsRightImageBL {
    left:-6px;
    position:absolute;
    bottom:-2px;
    transform:skew(-15deg);
  }

  .tipsRightImageBR {
    bottom:-2px;
    position:absolute;
    right:-6px;
    transform:skew(-15deg);
  }
  
  /* test superposition image et overlay  */
  .infoNavSerie {
    transition: transform 0.3s;
    cursor: pointer;
    position: relative;
    top: 0;
    left: 0;
  }
  .infoNavSerie:hover {
    display: block;
    background: rgba(255, 255, 255, .1);
    transform: scale(1.1);
    z-index: 999;
  }

  .infoNavSerie-couv {
    position: relative;
    top: 0;
    left: 0;
  }
  
  .infoNavSerie-overlay {    
    position: absolute;
    top: 0;
    left: 0;
  }
  
