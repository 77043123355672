.modalLogin {  
    display: grid;
    grid-template-columns: 1fr 0.2fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "leftModal rightModal";
    width: 100%;
  }
  .leftModal { grid-area: leftModal; }
  .rightModal { grid-area: rightModal; margin: auto; }