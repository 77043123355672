  /* Styles pour les écrans d'ordinateur */
  @media only screen and (min-width: 768px) {
    .container-grab {
      display:flex;
      font-family:'Comic Sans', cursive;
      padding:1vmin;    
    }
  }
  

    
    .inputEditeur {
     max-width: 100px !important; 
    }
  
    .inputParution {
      max-width: 60px !important; 
     }  
    
    .container-grab-panel {
      z-index: 0;
      margin: auto;
      flex:1;
      height:90px;
      margin:1vmin;
      overflow:hidden;
      position:relative;    
    }
    
    .container-grab-panel:nth-child(1) {
      flex-basis: 100px;
    }
    .container-grab-panel:nth-child(2) {
      flex-basis: 400px;
    }
    .container-grab-panel:nth-child(3) {
      flex-basis: 100px;
    }
    
    .serie-top-left-l2 {
      left:-6px;
      position:relative;
      top:30px;
      transform:skew(-15deg);
    }
  
    .serie-top-left-l3 {
      left:-6px;
      position:relative;
      top:10px;
      transform:skew(-15deg);
    }
  
    .serie-top-left-l4 {
      left:-6px;
      position:relative;
      top:10px;
      transform:skew(-15deg);
    }
    
    /* centrage bouton creation album */
  .buttonContainer {  
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      ". buttonCreate .";
  }
  
  .buttonCreate { grid-area: buttonCreate; margin: auto;}

    /* Styles pour les écrans mobiles */
    @media only screen and (max-width: 767px) {
      .container-grab {
        display:flex;
        padding:1vmin;    
      }
      .buttonContainer {  
        display: block;
      }
    }