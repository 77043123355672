  /* Styles pour les écrans d'ordinateur */
  @media only screen and (min-width: 768px) {
    .headerPage-comic {
      display:flex;
      font-family:'Comic Sans', cursive;
      padding:1vmin;
      background-color: rgb(39, 36, 36) !important;
      height:83px;
      top: 0;
    }
    .headerPage-panel {
      background-color:#fff;
      border:solid 2px #000;
      box-shadow:0 6px 6px -6px #000;
      flex:1;
      height:90px;
      margin-top: 0;
      margin-right:1vmin;
      position:relative;
    }
  }
  
  /* Styles pour les écrans mobiles */
  @media only screen and (max-width: 767px) {
    .headerPage-comic {
      display:flex;
      padding:1vmin;
      background-color: rgb(39, 36, 36) !important;
      height:83px;
      top: 0;        
    }
    .headerPage-panel {
      background-color:#fff;
      border:solid 2px #000;
      box-shadow:0 6px 6px -6px #000;
      flex:1;
      height:70px;
      margin-top: 0;
      margin-right:1vmin;
      position:relative;
    }
  }
    
.headerPage-text {
  background-color:#fff;
  border:solid 2px #000;
  margin:0;
  padding:3px 10px;
  font-size: max(1.9vmin, 12px);
  
}

.headerPage-top-left {
  left:-6px;
  position:absolute;
  top:-2px;
  transform:skew(-15deg);
}

.headerPage-bottom-right {
  bottom:-2px;
  position:absolute;
  right:-6px;
  transform:skew(-15deg);
}

.headerPage-speech {
  background-color:#fff;
  border:solid 2px #000;
  border-radius:12px;
  display:inline-block;
  margin:.5em;
  margin-left:20em;
  padding:.5em 1em;
  position:relative;
}

.headerPage-speech:before {
  border:solid 12px transparent;
  border-left:solid 12px #000;
  border-top:solid 12px #000;
  bottom:-24px;
  content:"";
  height:0;
  left:24px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}

.headerPage-speech:after {
  border:solid 10px transparent;
  border-left:solid 10px #fff;
  border-top:solid 10px #fff;
  bottom:-19px;
  content:"";
  height:0;
  left:27px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}

.headerPage-speech-big {
  background-color:#fff;
  border:solid 2px #000;
  border-radius:12px;
  display:inline-block;    
  position:relative;
  left: 50%;
  transform: translateX(-50%);
}

.headerPage-speech-big:before {
  border:solid 12px transparent;
  border-left:solid 12px #000;
  border-top:solid 12px #000;
  bottom:-24px;
  content:"";
  height:0;
  left:24px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}

.headerPage-speech-big:after {
  border:solid 10px transparent;
  border-left:solid 10px #fff;
  border-top:solid 10px #fff;
  bottom:-19px;
  content:"";
  height:0;
  left:27px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}


.headerPage-panel:nth-child(1) {
  flex-basis: 100px;
}
.headerPage-panel:nth-child(2) {
  flex-basis: 400px;
}
.headerPage-panel:nth-child(3) {
  flex-basis: 100px;
}

/* background colours */

.headerPage-panel:nth-child(1) {
  background-image:radial-gradient(circle, lightblue, deepskyblue);
}

.headerPage-panel:nth-child(2) {
  background-image:radial-gradient(circle, yellow, orange);
}

.headerPage-panel:nth-child(3) {
  background-image:radial-gradient(circle, palegreen, yellowgreen);
}

.headerPage-panel:nth-child(4n+4) {
  background-image:radial-gradient(circle, lightcoral, tomato);
}