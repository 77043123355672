 /* Styles pour les écrans d'ordinateur */
 @media only screen and (min-width: 768px) {
  .comic {
    display:flex;
    flex-wrap:wrap;
    font-family:'Comic Sans', cursive;
  }
}

/* Styles pour les écrans mobiles */
@media only screen and (max-width: 767px) {
  .comic {
    display:flex;
    flex-wrap:wrap;
  }
}
  
.panel {
  z-index: -1;
  background-color:#fff;
  border:solid 2px #000;
  box-shadow:0 6px 6px -6px #000;
  display:inline-block;
  flex:1 1;
  height:200px;
  margin:1vmin;
  overflow:hidden;
  position:relative;
}

.text {
  background-color:#fff;
  border:solid 2px #000;
  margin:0;
  padding:3px 10px;
}

.top-left {
  left:-6px;
  position:absolute;
  top:-2px;
  transform:skew(-15deg);
}

.bottom-right {
  bottom:-2px;
  position:absolute;
  right:-6px;
  transform:skew(-15deg);
}

.speech {
  background-color:#fff;
  border:solid 2px #000;
  border-radius:12px;
  display:inline-block;
  margin:.5em;
  padding:.5em 1em;
  position:relative;
}

.speech:before {
  border:solid 12px transparent;
  border-left:solid 12px #000;
  border-top:solid 12px #000;
  bottom:-24px;
  content:"";
  height:0;
  left:24px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}

.speech:after {
  border:solid 10px transparent;
  border-left:solid 10px #fff;
  border-top:solid 10px #fff;
  bottom:-19px;
  content:"";
  height:0;
  left:27px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}

.panel:nth-child(1) {
  flex-basis: 400px;
}
.panel:nth-child(2) {
  flex-basis: 200px;
}
.panel:nth-child(3) {
  flex-basis: 200px;
}
.panel:nth-child(4) {
  flex-basis: 200px;
}
.panel:nth-child(5) {
  flex-basis: 200px;
}
.panel:nth-child(6) {
  flex-basis: 200px;
}
.panel:nth-child(7) {
  flex-basis: 400px;
}
.panel:nth-child(8) {
  flex-basis: 200px;
}
.panel:nth-child(9) {
  flex-basis: 200px;
}

/* background colours */

.panel:nth-child(4n+1) {
  background-image:radial-gradient(circle, yellow, orange);
}

.panel:nth-child(4n+2) {
  background-image:radial-gradient(circle, lightblue, deepskyblue);
}

.panel:nth-child(4n+3) {
  background-image:radial-gradient(circle, palegreen, yellowgreen);
}

.panel:nth-child(4n+4) {
  background-image:radial-gradient(circle, lightcoral, tomato);
}