  /* Styles pour les écrans d'ordinateur */
  @media only screen and (min-width: 768px) {
    .search-comic {
      display:flex;
      flex-wrap:wrap;
      font-family:'Comic Sans', cursive;
      padding:1vmin;
      margin-left:2vmin;
    }
  }
  
  /* Styles pour les écrans mobiles */
  @media only screen and (max-width: 767px) {
    .search-comic {
      display:flex;
      flex-wrap:wrap;
      padding:1vmin;
      margin-left:2vmin;      
    }    
  }

.search-input-text {
  width: 480px;
}

.search-panel {
  z-index: 0;
  background-color:#fff;
  border:solid 2px #000;
  box-shadow:0 6px 6px -6px #000;
  display:inline-block;
  flex:1 1;
  height: 21vmin;
  width: 25vmin;
  margin:1vmin;
  overflow:hidden;
  position:relative;    
}

.search-speech {
  background-color:#fff;
  border:solid 2px #000;
  border-radius:12px;
  display:inline-block;
  margin: .5em;
  padding: .6em .6em;
  position:relative;  
}

.search-speech:before {
  border:solid 12px transparent;
  border-left:solid 12px #000;
  border-top:solid 12px #000;
  bottom:-24px;
  content:"";
  height:0;
  left:24px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}

.search-speech:after {
  border:solid 10px transparent;
  border-left:solid 10px #fff;
  border-top:solid 10px #fff;
  bottom:-19px;
  content:"";
  height:0;
  left:27px;
  position:absolute;
  transform:skew(-15deg);
  width:0;
}

.search-text {
  background-color:#fff;
  border:solid 2px #000;
  margin:0;
  padding:3px 10px;
  color: #000;  
}

.search-top-left {
  left:-6px;
  position:absolute;
  top:-2px;
  transform:skew(-15deg);
}

.search-bottom-right {
  bottom:-2px;
  position:absolute;
  right:-6px;
  transform:skew(-15deg);
}

.search-bottom-left {
  left:-6px;
  bottom:-2px;
  position:absolute;
  transform:skew(-15deg);
}

.search-panel img{
  z-index: -1;
  width: 100%;
  height: 200%;
  transition: transform 0.3s;
}

.search-panel img:hover{
  transform: scale(1.1);
}

.search-panel a{
  z-index: 1;
  width: 40%;
}
.search-panel:nth-child(n) {
  flex-basis: 0;
}
.search-bgcolor-orange {
  background-image:radial-gradient(circle, yellow, orange);
}
.search-bgcolor-blue {
  background-image:radial-gradient(circle, lightblue, deepskyblue);
}
.search-bgcolor-green {
  background-image:radial-gradient(circle, palegreen, yellowgreen);
}
.search-bgcolor-red {
  background-image:radial-gradient(circle, lightcoral, tomato);
}
.search-fontsize-title {
  font-size: max(3vmin, 12px);
}
